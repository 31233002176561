<ul class="sponsors-container">
  <div class="sponsors-item">
    <div class="title">
      <h3>Organizatorzy:</h3>
    </div>
    <div class="logos">
      <a target="_blank" *ngFor="let organizator of sponsors.organizators" href="{{organizator.url}}">
        <li>
          <img [class.bigger]="organizator.name === 'zuwp'" src="/assets/images/sponsors/{{organizator.img}}" alt="{{organizator.name}}">
        </li>
      </a>
    </div>
  </div>

  <div class="sponsors-item">
    <div class="title">
      <h3>Partnerzy:</h3>
    </div>
    <div class="logos">
      <a target="_blank" *ngFor="let partner of sponsors.partners" href="{{partner.url}}">
        <li>
          <img src="/assets/images/sponsors/{{partner.img}}" alt="{{partner.name}}">
        </li>
      </a>
    </div>
  </div>

  <div class="sponsors-item">
    <div class="title">
      <h3>Dofinansowanie:</h3>
    </div>
    <div class="logos">
      <a target="_blank" *ngFor="let sponsor of sponsors.sponsors" href="{{sponsor.url}}">
        <li>
          <img src="/assets/images/sponsors/{{sponsor.img}}" alt="{{sponsor.name}}">
        </li>
      </a>
    </div>
  </div>

  <div class="sponsors-item">
    <div class="title">
      <h3>Patronat Honorowy:</h3>
      <p class="konsul-xD">Konsul Ukrainy Oleksandr Plodystyi</p>
    </div>
    <div class="logos">
      <a target="_blank" *ngFor="let media of sponsors.honourPatron" href="{{media.url}}">
        <li>
          <img src="/assets/images/sponsors/{{media.img}}" alt="{{media.name}}">
        </li>
      </a>
    </div>
  </div>

  <div class="sponsors-item">
    <div class="title">
      <h3>Patronat Medialny:</h3>
    </div>
    <div class="logos">
      <a target="_blank" *ngFor="let media of sponsors.media" href="{{media.url}}">
        <li>
          <img src="/assets/images/sponsors/{{media.img}}" alt="{{media.name}}">
        </li>
      </a>
    </div>
  </div>
</ul>
