import {Sponsors} from '../interfaces/sponsor.interface';

export const sponsorsData: Sponsors = {
  // sponsors2: [
  //   {
  //     name: 'gdansk_airport',
  //     img: 'airport.jpg',
  //     url: 'https://www.airport.gdansk.pl/',
  //     description: ''
  //   }
  // ],
  organizators: [
    {
      name: 'zuwp',
      img: 'zuwp.png',
      url: 'http://www.zup.ukraina.com.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'tynka',
      img: 'tynka.png',
      url: 'http://www.zup.ukraina.com.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'navpaky',
      img: 'navpaky.jpg',
      url: 'https://www.facebook.com/teatrnavpaky/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
  ],
  honourPatron: [
    {
      name: 'mfa',
      img: 'mfa_full.jpg',
      url: 'https://mfa.gov.ua/ua',
      description: '<p>Lorem Ipsum jest r</p>'
    }
  ],
  sponsors: [
    {
      name: 'gdansk',
      img: 'gd.png',
      url: 'http://www.gdansk.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'logo_pomorskie',
      img: 'wp.png',
      url: 'https://pomorskie.eu/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'mswia',
      img: 'mswia.jpg',
      url: 'https://www.gov.pl/web/mswia/',
      description: '<p>Lorem Ipsum jest r</p>'
    }
  ],
  partners: [
    {
      name: 'b90',
      img: 'b90.jpg',
      url: 'http://www.b90.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'bofolk',
      img: 'bofolk.png',
      url: 'http://www.b90.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'moonphase',
      img: 'moonphase.png',
      url: 'https://moonphasestudio.com/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'pierogi',
      img: 'pierogi.png',
      url: 'http://www.pierogilwowskie.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'brodway',
      img: 'brodway.jpg',
      url: 'https://www.facebook.com/brodway.ag',
      description: '<p>Lorem Ipsum jest r</p>'
    }
  ],
  media: [
    {
      name: 'trojmiasto',
      img: 'trojmiasto.jpg',
      url: 'https://www.trojmiasto.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'nasha_polshcha',
      img: 'nasha_polscha.png',
      url: 'https://www.trojmiasto.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'radio_gdansk',
      img: 'rg.jpg',
      url: 'https://radiogdansk.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'nasze_slowo',
      img: 'ns.jpg',
      url: 'http://www.nasze-slowo.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    },
    {
      name: 'ua',
      img: 'ua.png',
      url: 'https://ua.pl/',
      description: '<p>Lorem Ipsum jest r</p>'
    }
  ]
};
