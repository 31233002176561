import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ScrollService } from '../../services/scroll.service';
import { VideosService } from '../../services/videos.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements AfterViewInit, OnInit {
  constructor(private scrollService: ScrollService, private videosService: VideosService) {}
  @ViewChild('videos', { static: false }) videos;

  youtubeVideos: any[];
  showInfo: boolean;

  async ngOnInit(): Promise<void> {
    await this.videosService.initGoogleClient();
    this.youtubeVideos = await this.videosService.loadVideos();
    this.showInfo = !this.youtubeVideos.length;
    this.youtubeVideos.forEach(video => {
      const videoDiv = document.createElement('div');
      videoDiv.innerHTML = video.player.embedHtml;
      videoDiv.classList.add('video');
      videoDiv.style.height = '400px';
      videoDiv.style.width = '100%';
      videoDiv.style.display = 'flex';
      videoDiv.style.justifyContent = 'center';
      videoDiv.style.paddingRight = '50px';

      videoDiv.children[0]['width'] = '480px';
      videoDiv.children[0]['height'] = '400px';
      this.videos.nativeElement.children[0].append(videoDiv);
    });
  }

  ngAfterViewInit(): void {
    this.scrollService.getElement().subscribe(el => {
      if (el === 'videos')
      {
        this[el].nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
}
