import {Component} from '@angular/core';
import {Sponsors} from '../../../../interfaces/sponsor.interface';
import {sponsorsData} from '../../../../static-data/sponsors.static-data';

@Component({
  selector: 'app-sponsorship',
  templateUrl: './sponsorship.component.html',
  styleUrls: ['./sponsorship.component.scss']
})
export class SponsorshipComponent {
  public sponsors: Sponsors = sponsorsData;
}
