import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {bandsData} from '../../../../static-data/bands.static-data';
import {DomSanitizer} from '@angular/platform-browser';
import {BandInterface} from '../../../../interfaces/band.interface';
import {TranslateService} from '@ngx-translate/core';
import { LanguageService } from '../../../services/language.service';

@Component({
  selector: 'app-single-band',
  templateUrl: './single-band.component.html',
  styleUrls: ['./single-band.component.scss']
})
export class SingleBandComponent implements OnInit {
  public band: BandInterface;
  public title: string;
  public description: string;
  public startTime: string;
  public city: string;
  public items: string[];
  constructor(private activatedRoute: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private translate: TranslateService,
              private languageService: LanguageService,
              ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.band = bandsData.find(band => band.path === params.path);
      this.title = `bands.${this.band.path}.title`;
      this.description = `bands.${this.band.path}.desc`;
      this.startTime = `bands.${this.band.path}.startTime`;
      this.city = `bands.${this.band.path}.city`;
    });
    this.switchLanguage();
  }

  switchLanguage(): void {
    this.languageService.getLanguage().subscribe(lang => {
      if (lang === 'pl' && this.band.path === 'tolhaje') {
        this.items = this.band.plItems;
      } else if (lang === 'ua' && this.band.path === 'tolhaje') {
        this.items = this.band.uaItems;
      }
      this.translate.use(lang);
    });
  }

  public getSanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
