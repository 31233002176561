<div class="artbox">
  <h1>ARTBOX</h1>

  <div class="artbox-description">
    <p [innerHTML]="'artbox.desc' | translate"></p>
  </div>

  <div class="artbox-artists">
    <h1>Artyści</h1>
    <div class="artbox-artist" *ngFor="let artistName of artists">
      <img [src]="getImagePath(artistName)" />
      <p [innerHTML]="getTextPath(artistName) | translate"></p>
    </div>
  </div>
</div>
