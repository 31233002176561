export const bandsData = [
  {
    path: 'jerry',
    name: 'jerry heil',
    img: 'jerry.jpeg',
  },
  {
    path: 'art_dance',
    name: 'ukrainian art & dance',
    img: 'art_dance.jpg',
  },
  {
    path: 'patsyki',
    name: 'patsyki z franeka',
    img: 'patsyki.jpeg'
  },
  {
    path: 'elove',
    name: 'elove',
    img: 'elove.jpg',
  },
  {
    path: 'izi',
    name: 'izzi',
    img: 'izi.jpg'
  },
  {
    path: 'hylla',
    name: 'hylla',
    img: 'hylla.jpg'
  },
  {
    path: 'nadija',
    name: 'nadija',
    img: 'nadija.jpg',
  },
  {
    path: 'contrabas',
    name: 'contrabass',
    img: 'contrabas.jpeg',
  },
  {
    path: 'vilni',
    name: 'vilni',
    img: 'vilni.jpg',
  },
  {
    path: 'ventyk',
    name: 'DJ Ventyk',
    img: 'ventyk.jpg',
  },
  {
    path: 'empe',
    name: 'dj empe & dj woj',
    img: 'empe.jpg',
  },
  {
    path: 'marta',
    name: 'Marta Zwarycz',
    img: 'marta.jpg',
  },
  {
    path: 'ludy',
    name: 'Ludzie wolności',
    img: 'logo_red.png',
  }
];

export const galaArtists = [
  {
    path: 'vyrij',
    img: 'vyrij.jpg'
  },
  {
    path: 'szymbra',
    img: 'szymbra.jpg'
  },
  {
    path: 'nina',
    img: 'nina.jpeg'
  },
  {
    path: 'vira',
    img: 'vira.jpg'
  },
  {
    path: 'tabakov',
    img: 'tabakov.jpg'
  },
  {
    path: 'burmaka',
    img: 'burmaka.jpg'
  },
  {
    path: 'vlad',
    img: 'vlad.jpeg'
  },
  {
    path: 'iryna',
    img: 'iryna.jpg'
  },
  {
    path: 'eva',
    img: 'eva.jpg'
  },
  {
    path: 'alex',
    img: 'alex.jpg'
  },
  {
    path: 'hanna',
    img: 'hanna.jpg'
  },
  {
    path: 'daya',
    img: 'daya.jpeg'
  },
  {
    path: 'ulana',
    img: 'ulana.jpg'
  },
  {
    path: 'olena',
    img: 'olena.jpg'
  },
  {
    path: 'olek',
    img: 'olek.jpg'
  }
];

export const galaMusicians = [
  {
    path: 'walczak',
    img: 'walczak.jpeg'
  },
  {
    path: 'yasne',
    img: 'yasne.jpg'
  },
  {
    path: 'konrad',
    img: 'konrad.png'
  },
  {
    path: 'mateusz',
    img: 'mateusz.jpg'
  },
  {
    path: 'lukasz',
    img: 'lukasz.jpg'
  }
];
