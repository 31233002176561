import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  public $elementName: BehaviorSubject<string> = new BehaviorSubject('app');

  setElementName(lang: string): void {
    this.$elementName.next(lang);
  }

  getElement(): BehaviorSubject<string> {
    return this.$elementName;
  }

  scroll(element): void {
    element.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
