import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-artbox',
  templateUrl: './artbox.component.html',
  styleUrls: ['./artbox.component.scss']
})
export class ArtboxComponent implements OnInit {
  artists = ['ninka', 'natalia', 'iryna', 'orysia'];

  ngOnInit(): void {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  getTextPath(artistName: string): string {
    return `artbox.artists.${artistName}`;
  }

  getImagePath(artistName: string): string {
    return `/assets/images/artbox/${artistName}.jpg`;
  }
}
