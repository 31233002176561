<div class="single-band-container container-fluid">
  <div class="row">
    <div class="col-lg-offset-3 col-lg-6 col-md-offset-2 col-md-8">
      <div class="single-band row">
        <div class="img-container">
          <div class="singleband-header">
            <h3>{{title | translate}}</h3>
          </div>
          <img src="/assets/images/bands/{{band.img}}" alt="band-image">
        </div>
        <hr>
        <p class="band-description" [innerHTML]="description | translate"></p>
        <div class="items" *ngIf="band.path === 'tolhaje'">
          <p class="items-title" [innerHTML]="'bands.tolhaje.itemsTitle' | translate"></p>
          <ul class="items-list">
            <li class="item" *ngFor="let item of items">{{item}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


