<div class="section-lineup">
  <h2>{{'agenda.title' | translate}} JarmaROCK FEST</h2>
  <div class="lineup">
    <div class="concert-date">
      <h6>17.11.2023 {{'agenda.friday' | translate}}</h6>
      <h5>JarmarockFEST - {{'agenda.muza' | translate}}</h5>
    </div>
    <hr>
    <div class="text-lineup">
      <p>
        <span class="hour">19:00</span> - {{'agenda.opening' | translate}}
      </p>
      <p>
        <span class="hour">20:00 - 20:05</span> - Otwarcie i Projekt Marty Zvarych "Magia Obrzędów i Tradycji"
      </p>
      <p>
        <span class="hour">20:05 - 20:35</span> - Vilni
      </p>
      <p>
        <span class="hour">20:45 - 21:15</span> - ZaZhyve
      </p>
      <p>
        <span class="hour">21:25 - 22:05</span> - UKRAINIAN ART & DANCE COMPANY
      </p>
      <p>
        <span class="hour">22:15 - 23:05</span> - Izzi
      </p>
      <p>
        <span class="hour">23:20 - 00:20</span> - Hylla
      </p>
      <p>
        <span class="hour">00:30 - 01:50</span> - HarazD
      </p>
      <p>
        <span class="hour">02:00 - 04:00</span> - DJ EMPE & DJ WOJ
      </p>
    </div>
  </div>

  <div class="lineup">
    <div class="concert-date">
      <h6>18.11.2023 {{'agenda.saturday' | translate}}</h6>
      <h5>JarmarockFEST</h5>
    </div>
    <hr>
    <div class="text-lineup">
      <p>
        <span class="hour">18:00</span> - {{'agenda.opening' | translate}}
      </p>
      <p>
        <span class="hour">19:00 - 19:15</span> - Performance
      </p>
      <p>
        <span class="hour">19:20 - 20:05</span> - eLove
      </p>
      <p>
        <span class="hour">20:20 - 21:05</span> - ContraBASS
      </p>
      <p>
        <span class="hour">21:20 - 22:00</span> - Jerry Heil
      </p>
      <p>
        <span class="hour">22:15 - 23:25</span> - Patsyki z Franeka
      </p>
      <p>
        <span class="hour">23:35 - 00:45</span> - Nadija
      </p>
      <p>
        <span class="hour">00:55 - 04:30</span> - DJ Ventyk
      </p>
    </div>
  </div>
</div>
