import { Router } from '@angular/router';
import { AfterViewInit, Component, ViewChild } from '@angular/core';

import { LanguageService } from '../../services/language.service';
import { BandInterface } from '../../../interfaces/band.interface';
import { bandsData } from '../../../static-data/bands.static-data';
import { ScrollService } from '../../services/scroll.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit {
  @ViewChild('app', { static: false }) app;

  public item: string;
  public item2: string;
  public item3: string;
  public item4: string;
  public item5: string;
  public bands: BandInterface[] = bandsData;
  public isDesktop = this.deviceDetector.isDesktop();

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private scrollService: ScrollService,
    private deviceDetector: DeviceDetectorService
  ) {}

  ngAfterViewInit(): void {
    this.scrollService.getElement().subscribe(el => {
      if (el === 'app')
      {
        this[el].nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  setLanguage(lang: string): void {
    this.languageService.setLanguage(lang);
  }
}
