import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LanguageService } from '../../services/language.service';
import { ScrollService } from '../../services/scroll.service';
import { filter } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor(private languageService: LanguageService,
              private scrollService: ScrollService,
              private router: Router,
              private deviceDetector: DeviceDetectorService) {
  }

  public hideMenu: boolean;
  public isMobile: boolean;
  @ViewChild('menu', {static: false}) menu;
  @ViewChild('dropdownMenu', {static: false}) dropdownMenu;
  @ViewChild('ticketsMenu', {static: false}) ticketsMenu;
  @ViewChild('bandsMenu', {static: false}) bandsMenu;

  ngOnInit(): void {
    this.isMobile = this.deviceDetector.isMobile();
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((ev: NavigationStart) => {
      this.hideMenu = !['bands', 'artbox', 'gala'].includes(ev.url.split('/')[1]);
    });
  }

  navigateTo(element: string): void {
    if (['bands', 'artbox', 'gala'].includes(this.router.url.split('/')[1]) && element === 'app') {
      this.router.navigate(['/home']);
      this.scrollService.setElementName(element);
    } else {
      this.scrollService.setElementName(element);
    }
    if (element !== 'app') {
      this.toggleMenu();
    }
  }

  redirectToSingleTicket(type): void {
    const url = type === 0 ?
      'https://tickets.b90.pl/pl/event/555/46-jarmarock-fest-bilet-jednodniowy-1811' :
      'https://tickets.b90.pl/pl/event/556/46-jarmarock-fest-bilet-jednodniowy-1911';
    window.open(
      url,
      '_blank'
    );
  }

  redirectToTickets(): void {
    const url = 'https://tickets.b90.pl/pl/event/632/47-jarmarock-fest-17-1811';
    window.open(
      url,
      '_blank'
    );
  }

  setLanguage(lang: string): void {
    this.languageService.setLanguage(lang);
  }

  toggleMenu(): void {
    this.menu.nativeElement.classList.toggle('active');
  }

  toggleHistoryMenu(): void {
    this.dropdownMenu.nativeElement.classList.toggle('active');
    if (this.ticketsMenu.nativeElement.classList.value.includes('active')) {
      this.ticketsMenu.nativeElement.classList.toggle('active');
    }

    if (this.bandsMenu.nativeElement.classList.value.includes('active')) {
      this.bandsMenu.nativeElement.classList.toggle('active');
    }
  }

  toggleTicketsMenu(): void {
    this.ticketsMenu.nativeElement.classList.toggle('active');
    if (this.dropdownMenu.nativeElement.classList.value.includes('active')) {
      this.dropdownMenu.nativeElement.classList.toggle('active');
    }

    if (this.bandsMenu.nativeElement.classList.value.includes('active')) {
      this.bandsMenu.nativeElement.classList.toggle('active');
    }
  }

  toggleBandsMenu(): void {
    this.bandsMenu.nativeElement.classList.toggle('active');
    if (this.dropdownMenu.nativeElement.classList.value.includes('active')) {
      this.dropdownMenu.nativeElement.classList.toggle('active');
    }

    if (this.ticketsMenu.nativeElement.classList.value.includes('active')) {
      this.ticketsMenu.nativeElement.classList.toggle('active');
    }
  }

  redirectToHref(url: string): void {
    window.open(
      url,
      '_blank'
    );
  }
}
