import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../services/language.service';
import { ScrollService } from '../../services/scroll.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: [ './about.component.scss' ]
})
export class AboutComponent implements OnInit, AfterViewInit {
  constructor(
    private languageService: LanguageService,
    private translate: TranslateService,
    private scrollService: ScrollService) {
    this.translate.setDefaultLang('pl');
  }

  @ViewChild('about', { static: false }) about;

  ngOnInit(): void {
    this.languageService.getLanguage().subscribe(val => {
      this.translate.use(val);
    });
  }

  ngAfterViewInit(): void {
    this.scrollService.getElement().subscribe(el => {
      if (el === 'about')
      {
        this[el].nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }
}
