<nav class="navbar">
  <span *ngIf="hideMenu" (click)="toggleMenu()" class="navbar-toggle">
    <mat-icon>menu</mat-icon>
  </span>
  <div class="logo" (click)="navigateTo('app')">
    <img src="../../../assets/images/logo47.png">
  </div>

    <ul #menu *ngIf="hideMenu" class="menu">
      <li (click)="navigateTo('about')">
        <a [innerHTML]="'mainInfo.title' | translate"></a>
      </li>
      <li class="dropdown-menu" (click)="isMobile ? toggleBandsMenu() : navigateTo('bandsElement')">
        <a [innerHTML]="'bands.mainTitle' | translate"></a>
        <ul #bandsMenu class="dropdown-list bands" [ngClass]="{'bands': isMobile}">
          <div *ngIf="isMobile" (click)="navigateTo('bandsElement')"><a>Wszyscy</a></div>
          <div routerLink="/artbox"><a>Artbox</a></div>
        </ul>
      </li>
      <li (click)="navigateTo('videos')">
        <a>Video</a>
      </li>
      <li class="dropdown-menu" (click)="toggleTicketsMenu()">
        <a>Bilety</a>
        <ul #ticketsMenu class="dropdown-list tickets" [ngClass]="{'tickets': isMobile}">
<!--          <div (click)="redirectToSingleTicket(0)"><a>Jednodniowy (piątek)</a></div>-->
<!--          <div (click)="redirectToSingleTicket(1)"><a>Jednodniowy (sobota)</a></div>-->
          <div (click)="redirectToTickets()"><a>Dwudniowy</a></div>
        </ul>
      </li>
<!--      <li class="dropdown-menu" (click)="toggleHistoryMenu()">
        <a>Historia</a>
        <ul #dropdownMenu class="dropdown-list">
          <div (click)="redirectToHref('https://2022.jarmarock-fest.com')">
            <a>2022</a>
          </div>
          <div (click)="redirectToHref('https://2021.jarmarock-fest.com')">
            <a>2021</a>
          </div>
          <div (click)="redirectToHref('https://2020.jarmarock-fest.com')">
            <a>2020</a>
          </div>
          <div (click)="redirectToHref('https://2019.jarmarock-fest.com')">
            <a>2019</a>
          </div>
          <div (click)="redirectToHref('https://2018.jarmarock-fest.com')">
            <a>2018</a>
          </div>
        </ul>
      </li> -->
    </ul>

  <div class="languages">
    <a (click)="setLanguage('pl')"><span class="flag-icon flag-icon-pl"></span></a>
    <a (click)="setLanguage('ua')"><span class="flag-icon flag-icon-ua"></span></a>
  </div>


</nav>
