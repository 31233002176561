import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public $language: BehaviorSubject<string> = new BehaviorSubject('pl');

  setLanguage(lang: string): void {
    this.$language.next(lang);
  }

  getLanguage(): BehaviorSubject<string> {
    return this.$language;
  }
}
