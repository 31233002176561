import { Component, OnInit } from '@angular/core';
import { galaArtists, galaMusicians } from '../../../static-data/bands.static-data';

@Component({
  selector: 'app-gala',
  templateUrl: 'gala.component.html',
  styleUrls: ['./gala.component.scss']
})
export class GalaComponent implements OnInit{
  artists = galaArtists;
  musicians = galaMusicians;

  ngOnInit(): void {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  getArtistName(key: string, path: string): string {
    return `gala.${key}.${path}.name`;
  }

  getArtistImage(artist: {path: string, img: string}): string {
    return !['tabakov', 'burmaka'].includes(artist.path) ? `/assets/images/gala/${artist.img}` : `/assets/images/bands/${artist.img}`;
  }

  getTextPath(key: string, name: string): string {
    return `gala.${key}.${name}.desc`;
  }
}
