import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare const gapi: any;

@Injectable({
  providedIn: 'root'
})
export class VideosService {
  googleClient: any;

  async initGoogleClient() {
    const promise = new Promise((resolve, reject) => {
      gapi.load('client', () => {
        gapi.client.setApiKey(environment.API_KEY_2);
        gapi.client.load('https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest')
          .then(() => {
              resolve(gapi.client);
              console.log('GAPI client loaded for API');
            },
            err => {
              reject(err);
              console.error('Error loading GAPI client for API', err);
            });
      });
    });

    this.googleClient = await promise;
  }

  async loadVideos(): Promise<any> {
    const playlistId = 'UU4-TWoDbJVZSCQK5sXXdWlg';
    const items: any = await this.getPlayListItems(playlistId);
    const videoIds = items.map(item => item.snippet.resourceId.videoId).join();
    const promise = new Promise((resolve, reject) => {
      this.googleClient.youtube.videos.list({
        part: [
          'player',
          'snippet'
        ],
        id: videoIds
      }).then(res => resolve(res.result.items))
        .catch(err => reject(err));
    });
    const videos: any = await promise;
    return videos.filter(video => video.snippet.tags && video.snippet.tags.includes('#44JarmaRockFEST'));
  }

  async getChannel() {
    return new Promise((resolve, reject) => {
      this.googleClient.youtube.channels.list({
        part: [
          'snippet'
        ],
        forUsername: 'JarmarokTV'
      }).then(res => resolve(res.result.items))
        .catch(err => reject(err));
    });
  }

  getChannelDetails(id: string) {
    return new Promise((resolve, reject) => {
      this.googleClient.youtube.channels.list({
        id,
        part: [
          'contentDetails'
        ]
      }).then(res => resolve(res.result.items))
        .catch(err => reject(err));
    });
  }

  async getPlayListItems(playlistId: string) {
    return new Promise((resolve, reject) => {
      this.googleClient.youtube.playlistItems.list({
        part: [
          'snippet'
        ],
        playlistId,
        maxResults: 15
      }).then(res => resolve(res.result.items))
        .catch(err => {
          reject(err);
        });
    });
  }
}
