import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { SingleBandComponent } from './components/bands/single-band/single-band.component';
import { ArtboxComponent } from './components/artbox/artbox.component';
import { GalaComponent } from './components/gala/gala.component';


const appRoutes: Routes = [
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full' },
  { path: 'home',
    component: HomeComponent,
  },
  { path: 'bands/:path',
    component: SingleBandComponent
  },
  {
    path: 'artbox',
    component: ArtboxComponent
  },
  {
    path: 'gala',
    component: GalaComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
