import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {bandsData} from '../../../static-data/bands.static-data';
import {BandInterface} from '../../../interfaces/band.interface';
import { ScrollService } from '../../services/scroll.service';

@Component({
  selector: 'app-bands',
  templateUrl: './bands.component.html',
  styleUrls: [ './bands.component.scss' ]
})
export class BandsComponent implements AfterViewInit {
  @ViewChild('bandsElement', { static: false }) bandsElement;
  public bands: BandInterface[] = bandsData;

  constructor(private router: Router, private scrollService: ScrollService) {}

  ngAfterViewInit(): void {
    this.scrollService.getElement().subscribe(el => {
      if (el === 'bandsElement')
      {
        this[el].nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  public showBandDetails(band: BandInterface): Promise<boolean> {
    return this.router.navigate([ `bands/${band.path}` ]);
  }
}
