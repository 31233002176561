<div #about class="about-container">
  <div class="main-information">
    <div class="description">
      <h2 class="section-title">{{'mainInfo.title' | translate}}</h2>
      <p [innerHTML]="'mainInfo.part1' | translate"></p>
      <p [innerHTML]="'mainInfo.part2' | translate"></p>
      <p [innerHTML]="'mainInfo.part3' | translate"></p>
      <p [innerHTML]="'mainInfo.part4' | translate"></p>
      <p [innerHTML]="'mainInfo.part5' | translate"></p>
      <p [innerHTML]="'mainInfo.part6' | translate"></p>
      <p [innerHTML]="'mainInfo.part7' | translate"></p>
    </div>
  </div>
  <div class='poster-information'>
    <div class="poster-container">
      <img src="/assets/images/poster47.jpg" alt="">
    </div>
    <div class="description">
      <p [innerHTML]="'mainInfo.poster.part1' | translate"></p>
      <p [innerHTML]="'mainInfo.poster.part2' | translate"></p>
      <p [innerHTML]="'mainInfo.poster.part3' | translate"></p>
    </div>
  </div>
  <div class="description second-part">
    <div class="forum-main">
      <h3>{{'forum.mainTitle' | translate}}</h3>
      <p style="text-align: center; margin: 0;">
        <strong>{{'forum.date' | translate}}</strong>
      </p>
    </div>
    <div class="forum-image">
      <img src="/assets/images/forum.jpg" alt="">
    </div>
    <h5 [innerHTML]="'forum.title' | translate"></h5>
    <p [innerHTML]="'forum.info1' | translate"></p>
  </div>

  <div class="description forum-speakers">
    <div style="margin-bottom: 10px">
      <h3>{{'forum.host' | translate}}</h3>
      <span>
        <strong>{{'forum.mariana.name' | translate}}</strong>
        {{'forum.mariana.desc' | translate}}
      </span>
    </div>
    <h3>{{'forum.speakers' | translate}}</h3>
    <ul>
      <li>
        <span>
        <strong>{{'forum.twins.name' | translate}}</strong>
          {{'forum.twins.desc' | translate}}
        </span>
      </li>
      <li>
        <span>
          <strong>{{'forum.tania.name' | translate}}</strong>
          {{'forum.tania.desc' | translate}}
        </span>
      </li>
      <li>
        <span>
        <strong>{{'forum.anna.name' | translate}}</strong>
          {{'forum.anna.desc' | translate}}
        </span>
      </li>
      <li>
        <span>
        <strong>{{'forum.iryna.name' | translate}}</strong>
          {{'forum.iryna.desc' | translate}}
        </span>
      </li>
    </ul>
  </div>

  <div class="description second-part">
    <div class="forum-main">
      <h3>{{'artbox.mainTitle' | translate}}</h3>
    </div>
    <div class="forum-image">
      <img style="cursor: pointer" src="/assets/images/artbox.jpg" routerLink="/artbox">
    </div>
    <p [innerHTML]="'artbox.desc' | translate"></p>
  </div>
  <app-agenda></app-agenda>
</div>



