import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    this.translate.setDefaultLang('pl');
  }
  title = 'jarmarock-single-page';
  @ViewChild('app', { static: false }) app;

  navigateTo(element: string): void {
    if (element === 'app') {
      this[element].nativeElement.scrollTo(0, {behavior: 'smooth'});
    }
    this[element].nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}

