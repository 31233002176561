<div class="gala">
  <h1>{{'gala.title' | translate}}</h1>

  <p class="gala-description">{{'gala.info1' | translate}}</p>
  <p class="gala-description">{{'gala.info2' | translate}}</p>

  <h1>{{'gala.artistsName' | translate}}:</h1>

  <div *ngFor="let artist of artists" class="gala-artists-wrapper">
    <h2>{{getArtistName('artists', artist.path) | translate}}</h2>
    <div class="gala-artist-container">
      <div>
        <img [src]="getArtistImage(artist)">
      </div>
      <p>{{getTextPath('artists', artist.path) | translate}}</p>
    </div>
  </div>

  <h1>{{'gala.musiciansName' | translate}}:</h1>

  <div *ngFor="let artist of musicians" class="gala-artists-wrapper">
    <h2>{{getArtistName('musicians', artist.path) | translate}}</h2>
    <div class="gala-artist-container">
      <div>
        <img [src]="getArtistImage(artist)">
      </div>
      <p>{{getTextPath('musicians', artist.path) | translate}}</p>
    </div>
  </div>
</div>
