<div #bandsElement class="row bands">
  <div class="band-container">
      <h2 class="section-title">
        {{'bands.mainTitle' | translate}}
      </h2>
      <ul class="band-gallery">
        <a *ngFor="let bandsTile of bands" (click)="showBandDetails(bandsTile)">
          <li *ngIf="bandsTile.path === 'empe'">
            <h4 class="text">{{bandsTile.name}}</h4>
            <img class="dj-image" src="/assets/images/bands/empe.jpg" alt="{{bandsTile.name}}">
            <img class="dj-image" src="/assets/images/bands/woj.jpg" alt="{{bandsTile.name}}">
          </li>
          <li *ngIf="bandsTile.path !== 'empe'">
            <h4 class="text">{{bandsTile.name}}</h4>
            <img class="band-image" src="/assets/images/bands/{{bandsTile.img}}" alt="{{bandsTile.name}}">
          </li>
        </a>
      </ul>
  </div>
</div>
