<div #app class="video-container">
  <video *ngIf="!isDesktop" src="/assets/videos/JarmaROCK-mobile.mp4" [muted]="'muted'" loop autoplay></video>
  <video *ngIf="isDesktop" src="/assets/videos/JarmaROCK.mp4" [muted]="'muted'" loop autoplay></video>
  <div class="logo">
    <ul class="social-icons">
      <li><a href="https://www.facebook.com/JarmaROCKFEST/" target="_blank"><img src="/assets/icons/facebook.svg"></a></li>
      <li><a href="https://www.instagram.com/jarmarockfest/" target="_blank"><img src="/assets/icons/instagram.svg"></a></li>
      <li><a href="https://www.youtube.com/channel/UC4-TWoDbJVZSCQK5sXXdWlg" target="_blank">
        <img src="/assets/icons/youtube.svg"></a></li>
    </ul>
    <div class="name">
    </div>
  </div>
</div>
<main id="container-entrance">
  <app-about></app-about>
  <app-bands></app-bands>
  <app-videos></app-videos>

  <app-sponsorship></app-sponsorship>
</main>



