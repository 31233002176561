import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatButtonModule } from '@angular/material/button';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { VideosComponent } from './components/videos/videos.component';


import { AboutComponent } from './components/about/about.component';
import { BandsComponent } from './components/bands/bands.component';
import { SingleBandComponent } from './components/bands/single-band/single-band.component';
import { AgendaComponent } from './components/about/agenda/agenda.component';
import { SponsorshipComponent } from './components/about/sponsorship/sponsorship.component';
import { HomeComponent } from './components/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { GtagModule } from 'angular-gtag';
import { ArtboxComponent } from './components/artbox/artbox.component';
import { GalaComponent } from './components/gala/gala.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AboutComponent,
    BandsComponent,
    VideosComponent,
    SingleBandComponent,
    AgendaComponent,
    SponsorshipComponent,
    HomeComponent,
    ArtboxComponent,
    GalaComponent
  ],
  imports: [
    BrowserModule,
    IvyCarouselModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
      }),
    GtagModule.forRoot({ trackingId: 'UA-117115240-1', trackPageviews: true })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    history.scrollRestoration = 'manual';
  }
}

export function httpTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
